import * as React from 'react';
import { IDropDownMenuProps } from '../../../DropDownMenu.types';
import DropDownMenuBase from '../../DropDownMenuBase';
import { MenuButtonProps } from '../../../../MenuButton/MenuButton.types';

type LinesMenuButtonProps = IDropDownMenuProps & {
  styles: any;
  Button: React.FC<MenuButtonProps>;
};

const LinesMenuButton: React.FC<LinesMenuButtonProps> = props => {
  const { styles, Button } = props;
  return <DropDownMenuBase {...props} styles={styles} Button={Button} />;
};

export default LinesMenuButton;
