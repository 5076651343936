import * as React from 'react';
import { IDropDownMenuProps } from '../../../DropDownMenu.types';
import LinesMenuButtonNSkin from '../../../../MenuButton/viewer/skinComps/BaseButton/LinesMenuButtonNSkin';
import LinesMenuButton from './LinesMenuButton';
import styles from './LinesMenuButtonSkin.scss';

const LinesMenuButtonSkin: React.FC<IDropDownMenuProps> = props => {
  return (
    <LinesMenuButton {...props} styles={styles} Button={LinesMenuButtonNSkin} />
  );
};

export default LinesMenuButtonSkin;
